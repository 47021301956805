import $ from "jquery";

export default function scroll_animation_module() {
    const servicesButton = $('#a_services');
    const insuranceButton = $('#a_insurance');
    const servicesButtonMob = $('#a_services_mob');
    const insuranceButtonMob = $('#a_insurance_mob');
    const contactButton = $('#a_contact');
    const contactButtonMob = $('#a_contact_mob');
    const contactButton1 = $('#a_contact_1');
    const contactButton2 = $('#a_contact_2');

    servicesButton.add(servicesButtonMob).click(function (event: JQuery.ClickEvent) {
        event.preventDefault();

        if ($('.menu-panel').hasClass('show')) {
            $('.menu-panel').removeClass('show');
            $('.menu-background').removeClass('show');
            $('.menu').show();
            $('.menu-close').hide();
        }

        if ($('#services').length) {
            $('html, body').animate({
                scrollTop: $('#services').offset().top
            }, 1000);
        }
    });

    insuranceButton.add(insuranceButtonMob).click(function (event: JQuery.ClickEvent) {
        event.preventDefault();

        if ($('.menu-panel').hasClass('show')) {
            $('.menu-panel').removeClass('show');
            $('.menu-background').removeClass('show');
            $('.menu').show();
            $('.menu-close').hide();
        }

        if ($('#insurance').length) {
            $('html, body').animate({
                scrollTop: $('#insurance').offset().top - $(window).height() * 0.2
            }, 1000);
        }
    });

    contactButton.add(contactButtonMob).add(contactButton1).add(contactButton2).click(function (event:  JQuery.ClickEvent) {
        event.preventDefault();

        if ($('.menu-panel').hasClass('show')) {
            $('.menu-panel').removeClass('show');
            $('.menu-background').removeClass('show');
            $('.menu').show();
            $('.menu-close').hide();
        }

        if ($('#contact').length) {
            $('html, body').animate({
                scrollTop: $('#contact').offset().top + 100
            }, 1000);
        }
    });
}