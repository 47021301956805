import '/src/styles/index.scss';
import $ from 'jquery';

import menu_module from "./modules/menu_module";
import scroll_animation_module from "./modules/scroll_animation_module";
import poukaz_form_module from "./modules/poukaz_module";
import poukaz_qr_module from "./modules/poukaz_qr_module";

'use strict';
$(document).ready(function(){
    menu_module();
    scroll_animation_module();
    poukaz_form_module();
    poukaz_qr_module();
});
