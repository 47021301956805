import $ from "jquery";

export default function datepicker_module(getOrderNum: () => number, dates: string[]) {

    const datepicker = $("#datepicker");

    if (datepicker.length) {
        (async () => {
            document.head.insertAdjacentHTML("beforeend", `
                <link rel="stylesheet" href="https://ajax.googleapis.com/ajax/libs/jqueryui/1.12.1/themes/base/jquery-ui.css">
                <style>
                    .ui-datepicker {
                        font-family: 'Quicksand', sans-serif;
                        font-size: 110%;
                    }
                    .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
                        text-align: center;
                        border: none;
                        background: #f6f6f6;
                        font-weight: normal;
                        color: #000;
                        padding: 10px;
                    }
                    .ui-state-active, .ui-widget-content .ui-state-active {
                        background: #20275B;
                        color: #fff;
                    }
                    .ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
                        background: rgba(0,198,168,0.15);
                        color: #000;
                    }
                </style>
            `);
            // @ts-ignore
            const {default: datepickerFactory} = await import(/* webpackChunkName: "datepickerFactory" */ "jquery-datepicker");
            // @ts-ignore
            const {default: datepickerCSFactory} = await import(/* webpackChunkName: "datepickerCSFactory" */ "jquery-datepicker/i18n/jquery.ui.datepicker-cs");
            datepickerFactory($);
            datepickerCSFactory($);
            datepicker.datepicker();
            $.datepicker.regional['cs'];

            datepicker.on("change", function () {
                dates[getOrderNum()] = datepicker.val();
            });
        })();
    }
}