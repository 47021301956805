import $ from "jquery";

export default function select_input_module(getOrderNum: () => number,
                                            selectedInsurance: [{id: string, name: string}],
                                            selectedProducts: [{ id: string; name: string; img: string; stock: string }] | [],
                                            selectedMonths: number[],
                                            price: number[],
                                            calculateProduct: any) {

    const selects: JQuery = $('.select');
    if (!selects.length) return;


    $(document).on('click', function (e: JQuery.ClickEvent) {
        selects.each(function () {
            const select = $(this);

            if (!select.is(e.target) && select.has(e.target).length === 0) {
                hideSelect(select);
            }
        });
    });

    $(document).on('click', '.select__option', function () {
        // @ts-ignore
        const option: JQuery = $(this),
            select = option.closest('.select');

        const type = select.find('input').attr('name');

        if (type === 'product') {
            if (option.hasClass('select__option--nostock')) return;
            selectedProducts[getOrderNum()] = {id: option.data('id'), name: option.data('name'), img: option.data('img'), stock: option.data('stock')};
            price[getOrderNum()] = Math.round(option.data('price') * 100) / 100;

        } else if (type === 'months') {
            selectedMonths[getOrderNum()] = option.data('id');

        } else if (type === 'insurance') {
            selectedInsurance[0] = {id: option.data('id'), name: option.data('name')};
        }

        select.find('input').removeClass('is-invalid');
        calculateProduct();
        hideSelect(select);
    });


    selects.each(function () {
        const select = $(this),
            input = select.find('input'),
            svg = select.find('svg');

        svg.css('transition', 'transform 0.3s ease');

        input.on('input', function () {
            !select.hasClass('select--toggled') && showSelect(select);

            const searched = input.val().toLowerCase(),
                options: JQuery = select.find('.select__option');

            options.each(function () {
                const option = $(this);
                option.find('.select__text').text().toLowerCase().includes(searched) ? option.show() : option.hide();
            });
            updateSelectHeight(select);
        });

        select.on('click', function (e: JQuery.ClickEvent) {
            if (svg.is(e.target)) {
                !select.hasClass('select--toggled') ? showSelect(select) : hideSelect(select);
            } else {
                !select.hasClass('select--toggled') && showSelect(select);
            }
        });
    });


    const showSelect = (select: JQuery) => {
        const type = select.find('input').attr('name') || 'default',
            data = getData(type);

        select.find('input').val('');

        select.append(renderOptions(type, data));
        updateSelectHeight(select);
        select.addClass('select--toggled');
    };

    const hideSelect = (select: JQuery) => {
        const type = select.find('input').attr('name') || 'default';

        if (type === 'product') {
            select.find('input').val(selectedProducts[getOrderNum()] === undefined ? '' : `${selectedProducts[getOrderNum()].id} [${selectedProducts[getOrderNum()].name}]`);
        } else if (type === 'months') {
            select.find('input').val(getData('months')[selectedMonths[getOrderNum()]] === undefined ? '' : `${getData('months')[selectedMonths[getOrderNum()]]}`);
        } else if (type === 'insurance') {
            select.find('input').val(selectedInsurance[0].name === '' ? '' : `${selectedInsurance[0].id} [${selectedInsurance[0].name}]`);
        }

        select.removeClass('select--toggled');
        setTimeout(function () {select.find('.select__options').remove();}, 200);
    };

    const getData = (type: string): (product|month|insurance)[] => {
        if (type === 'product') {
            //  TODO MOCK DATA
            return [
                {
                    id: 5009783,
                    name: 'MoliCare Premium Pants 4',
                    stock: 125,
                    img: 'https://via.placeholder.com/75x75/',
                    price: 1.99
                },
                {
                    id: 5009784,
                    name: 'MoliCare Premium Pants 5',
                    stock: 0,
                    img: 'https://via.placeholder.com/75x75/',
                    price: 2.99
                },
                {
                    id: 5009785,
                    name: 'MoliCare Premium Pants 6',
                    stock: 58,
                    img: 'https://via.placeholder.com/75x75/',
                    price: 0.99
                },
                {
                    id: 5009786,
                    name: 'MoliCare Premium Pants 7',
                    stock: 282,
                    img: 'https://via.placeholder.com/75x75/',
                    price: 5.99
                }
            ];

        } else if (type === 'months') {
            return [
                '1 měsíc',
                '2 měsíce',
                '3 měsíce'
            ];

        } else if (type === 'insurance') {
            return [
                {id: 111, name: "Všeobecná zdravotní pojišťovna"},
                {id: 201, name: "Vojenská zdravotní pojišťovna České republiky"},
                {id: 205, name: "Česká průmyslová zdravotní pojišťovna"},
                {id: 207, name: "Oborová zdravotní pojišťovna zaměstnanců bank, pojišťoven a stavebnictví"},
                {id: 209, name: "Zaměstnanecká pojišťovna Škoda"},
                {id: 211, name: "Zdravotní pojišťovna ministerstva vnitra České republiky"},
                {id: 213, name: "Revírní bratrská pokladna"}
            ]

        } else {
            return [];
        }
    };

    interface product {
        id: number;
        name: string;
        stock: number;
        img: string;
        price: number;
    }

    type month = string;

    interface insurance {
        id: number;
        name: string;
    }

    const renderOptions = (type: string, data: (product|month|insurance)[]): string => {
        if (type === 'product') {
            return `
                <div class="select__options">
                    ${data.map(item  => `
                        <div class="select__option select__option--product ${selectedProducts[getOrderNum()] !== undefined && selectedProducts[getOrderNum()].id === item.id && 'select__option--selected'} ${item.stock === 0 ? 'select__option--nostock' : ''}" data-id="${item.id}" data-name="${item.name}" data-img="${item.img}" data-price="${item.price}" data-stock="${item.stock}">
                            <div class="row align-items-center">
                                <div class="col-9">
                                    <p class="select__text">${item.id} - ${item.name}</p>
                                    <p class="p-small">${item.stock !== 0? `skladem ${item.stock} ks` : `zboží není skladem`}</p>
                                </div>
                                <div class="col-3">
                                    <img src="${item.img}" alt="${item.name}" loading="lazy">
                                </div>
                            </div>   
                        </div>
                    `).join('')}
                </div>
            `;
        } else if (type === 'months') {
            return `
                <div class="select__options">
                    ${data.map(month => `
                        <div class="select__option select__option--months ${selectedMonths[getOrderNum()] !== undefined && selectedMonths[getOrderNum()] === parseInt(data.indexOf(month)) && 'select__option--selected'}" data-id="${data.indexOf(month)}">
                             <div class="row align-items-center">
                                <div class="col-12">
                                    <p class="select__text">${month}</p>
                                </div>
                            </div>
                        </div>
                    `).join('')}
                </div>
            `;
        } else if (type === 'insurance') {
            return `
                <div class="select__options">
                    ${data.map(item => `
                        <div class="select__option select__option--insurance ${selectedInsurance[0].id === item.id && 'select__option--selected'}" data-id="${item.id}" data-name="${item.name}">
                            <div class="row align-items-center">
                                <div class="col-10">
                                    <p class="select__text" style="font-size: 14px; margin-bottom: 0;">${item.name}</p>
                                </div>
                                <div class="col-2">
                                    <p class="select__text text-center">${item.id}</p>
                                </div>
                            </div>
                        </div>
                    `).join('')}
                </div>
            `;
        } else {
            return '';
        }
    };

    const updateSelectHeight = (select: JQuery) => {
        const options = select.find('.select__option'),
            visibleOptions = options.filter(':visible').length,
            height = options.first().outerHeight() || 100;


        select.find('.select__options').css('height', (visibleOptions * height) + 2 + 'px');
    }
}