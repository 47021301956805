import $ from "jquery";
// TODO: NOT REQUIRED
export default function checkboxes_module() {
    const classicPoukazCheckboxes =  $(".classicPoukaz__checkbox");
    classicPoukazCheckboxes.on("click", function (e: JQuery.ClickEvent) {
        const inputName = $(this).find("input").attr("name");
        const input = $(`input[name=${inputName}]`);

        if (input.filter(":checked").length === 0) {
            input.attr("required", true);
        } else {
            input.removeAttr("required");
            input.prop("checked", false);
            $(e.target).closest('input').prop("checked", true);
        }
    });
};