import $ from 'jquery';
import select_input_module from "./select_input_module";
import checkboxes_module from "./checkboxes_module";
import num_input_module from "./num_input_module";
import datepicker_module from "./datepicker_module";

export default function poukaz_form_module() {

    let orderNumber: number = 0;
    let selectedInsurance: [{ id: string, name: string }] = [{id: "", name: ""}];

    let selectedProducts: [{ id: string; name: string; img: string; stock: string }] | [] = [];
    let selectedMonths: number[] = [];
    let baleniCount: number[] = [];
    let dates: string[] = [];
    let insuranceDiscount: number[] = [];
    let price: number[] = [];

    const successMessage = "Obdrželi jsme Váš ePoukaz.<br>Děkujeme.",
        errorMessage = "ePoukaz se nepodařilo nahrát.<br>Zkuste to prosím znovu.";

    if ($('.classicPoukaz').length) {
        (async () => {
            const {default: Modal} = await import(/* webpackChunkName: "Modal" */ "./modal_module");
            const modal = new Modal();


            const calculateProduct = () => {
                const item = selectedProducts[getOrderNum()],
                    count = baleniCount[getOrderNum()] || 0,
                    insurance = insuranceDiscount[getOrderNum()],
                    priceTotal = ((baleniCount[getOrderNum()] || 0) * price[getOrderNum()]).toFixed(2); // todo: insurance

                if (item === undefined || count === undefined || priceTotal === undefined) { return; }

                insurance && hrazeno.text(insurance + " Kč");
                priceTotal && doplatek.text(priceTotal + " Kč");

                const productShowcase = `
                                <div class="col-12 col-md-2 mb-3 mb-md-0">
                                    <img src="${item.img}" alt="${item.name}">
                                </div>
                                <div class="col-12 col-md-6 mb-3 mb-md-0">
                                    <p>${item.name}</p>
                                </div>
                                <div class="col-6 col-md-2">
                                    <p>${count} ks</p>
                                </div>
                                <div class="col-6 col-md-2">
                                    <p>${priceTotal} Kč</p>
                                </div>
                            `;
                $('.classicPoukaz__productShowcase_content').empty().append(productShowcase);
            }

            const clearCalculatedProduct = () => {
                hrazeno.text("0 Kč");
                doplatek.text("0 Kč");
                const showcaseEmpty = `<div class="col-12"><p class="classicPoukaz__productShowcase_nothing">Zatím nemáte vybranou žádnou pomůcku</p></div>`;
                $('.classicPoukaz__productShowcase_content').empty().append(showcaseEmpty);
            }



            // Inputs functionality
            const getOrderNum = () => {
                return orderNumber;
            };
            select_input_module(getOrderNum, selectedInsurance, selectedProducts, selectedMonths, price, calculateProduct);
            checkboxes_module();
            num_input_module(getOrderNum, baleniCount, calculateProduct);
            datepicker_module(getOrderNum, dates);

            const form: JQuery = $('#formPoukaz');
            const inputs = form.find('input');
            const sendAll1: JQuery = $('#sendAllButton1');
            const sendAll2: JQuery = $('#sendAllButton2');
            const addOrder: JQuery = $('#addOrderButton');
            const prefillAddress: JQuery = $('#prefillAddress');
            const hrazeno: JQuery = $('#hrazeno');
            const doplatek: JQuery = $('#doplatek');

            const summary: JQuery = $('.classicPoukaz__summary');
            const content: JQuery = $('.classicPoukaz__summaryContent');


            inputs.on('change', function () {
                checkIfAllInputsAreValid();
                checkValidity($(this));
            });
            inputs.on('keyup', function () {
                checkIfAllInputsAreValid()
            });
            $(document).on('click', function () {
                checkIfAllInputsAreValid()
            });
            const checkIfAllInputsAreValid = () => {
                let valid = true;
                inputs.each(function () {
                    if ($(this).attr('required') && !$(this).val()) {
                        valid = false;
                    }
                    if ($(this).attr('type') === 'email' && !validateEmail($(this).val())) {
                        valid = false;
                    }
                    if ($(this).attr('type') === 'tel' && !validatePhone($(this).val())) {
                        valid = false;
                    }
                    if ($(this).attr('type') === 'number' && $(this).val() === '0') {
                        valid = false;
                    }
                    if ($(this).attr('name') === 'adress_psc' && !validatePsc($(this).val())) {
                        valid = false;
                    }
                    if ($(this).attr('name') === 'psc' && !validatePsc($(this).val())) {
                        valid = false;
                    }
                });
                if (valid) {
                    sendAll1.attr('aria-disabled', 'false');
                    addOrder.attr('aria-disabled', 'false');
                } else {
                    sendAll1.attr('aria-disabled', 'true');
                    addOrder.attr('aria-disabled', 'true');
                }
            }


            addOrder.on('click', function (e) {
                e.preventDefault();

                if (addOrder.attr('aria-disabled') === 'true') {
                    markAllInvalid();
                    return;
                }

                if (orderNumber === 0) {
                    form.parent().addClass('col-lg-8');
                    summary.parent().removeClass('d-none');
                }

                content.empty();
                for (let i = 0; i < orderNumber + 1; i++) { //TODO: INSURANCE
                    const product = `<div class="classicPoukaz__summaryItem">
                                        <div class="row">
                                            <div class="col-9"><p>${selectedProducts[i].name}</p></div>
                                            <div class="col-3"><p>${baleniCount[i]} ks</p></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-9"><p>Doplatek</p></div>
                                            <div class="col-3"><p>${(price[i] * baleniCount[i]).toFixed(2)} Kč</p></div>
                                        </div>
                                        <div class="classicPoukaz__summaryCancel">
                                            <button data-order="${i}">Zrušit poukaz</button>
                                        </div>
                                    </div>`
                    content.append(product);
                }


                orderNumber++;
                $('input[name="product"]').val('');
                $('input[name="pocet_baleni"]').val('0');
                $('input[name="months"]').val('');
                $('input[name="date"]').val('');
                clearCalculatedProduct();


                $('html, body').animate({
                    scrollTop: summary?.offset()?.top ?? 0
                }, 500);
            });


            $(document).on('click', '.classicPoukaz__summaryCancel button', function () {
                const orderNum = $(this).data('order');

                selectedProducts.splice(orderNum, 1);
                selectedMonths.splice(orderNum, 1);
                baleniCount.splice(orderNum, 1);
                dates.splice(orderNum, 1);
                insuranceDiscount.splice(orderNum, 1);
                price.splice(orderNum, 1);


                orderNumber--;
                $('input[name="product"]').val('');
                $('input[name="pocet_baleni"]').val('0');
                $('input[name="months"]').val('');
                $('input[name="date"]').val('');

                if (orderNumber === 0) {
                    form.parent().removeClass('col-lg-8');
                    summary.parent().addClass('d-none');
                }

                content.empty();
                for (let i = 0; i < orderNumber + 1; i++) {
                    const product = `<div class="classicPoukaz__summaryItem">
                                        <div class="row">
                                            <div class="col-9"><p>${selectedProducts[i].name}</p></div>
                                            <div class="col-3"><p>${baleniCount[i]} ks</p></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-9"><p>Doplatek</p></div>
                                            <div class="col-3"><p>${price[i]} Kč</p></div>
                                        </div>
                                        <div class="classicPoukaz__summaryCancel">
                                            <button data-order="${i}">Zrušit poukaz</button>
                                        </div>
                                    </div>`
                    content.append(product);
                }
            });

            form.on('submit', function (e) {
                e.preventDefault();

                $('.classicPoukaz__generatedInput').remove();
                for (let i = 0; i < orderNumber + 1; i++) {
                    const order = `<input class="classicPoukaz__generatedInput" type="hidden" name="order[${i}][product]" value="${selectedProducts[i].id}">
                                   <input class="classicPoukaz__generatedInput" type="hidden" name="order[${i}][months]" value="${selectedMonths[i]}">
                                   <input class="classicPoukaz__generatedInput" type="hidden" name="order[${i}][baleniCount]" value="${baleniCount[i]}">
                                   <input class="classicPoukaz__generatedInput" type="hidden" name="order[${i}][date]" value="${dates[i]}">
                                   <input class="classicPoukaz__generatedInput" type="hidden" name="order[${i}][insuranceDiscount]" value="${insuranceDiscount[i]}">
                                   <input class="classicPoukaz__generatedInput" type="hidden" name="order[${i}][price]" value="${price[i]}">`
                    form.append(order);
                }

                $.post('/api/poukaz', form.serialize(), (data: { success: boolean, message: string }) => {
                    if (data.success) {
                        data.message ?
                            modal.show(data.message, 'success')
                            :
                            modal.show(successMessage, 'success');
                    } else {
                        data.message ?
                            modal.show(data.message, 'error')
                            :
                            modal.show(errorMessage, 'error');
                    }
                    clearForm();
                })
                .fail(() => {
                    modal.show(errorMessage, 'error');
                    clearForm();
                });
            });

            sendAll1.on('click', function (e) {
                e.preventDefault();

                if (sendAll1.attr('aria-disabled') === 'true') {
                    markAllInvalid();
                    return;
                }

                $('input[name="product"]').val('');
                $('input[name="months"]').val('');
                $('input[name="pocet_baleni"]').val('0');
                $('input[name="date"]').val('');
                form.submit();
            });

            sendAll2.on('click', function (e) {
                e.preventDefault();

                form.find('input').removeAttr('required');
                selectedProducts.splice(orderNumber, 1);
                selectedMonths.splice(orderNumber, 1);
                baleniCount.splice(orderNumber, 1);
                dates.splice(orderNumber, 1);
                insuranceDiscount.splice(orderNumber, 1);
                price.splice(orderNumber, 1);
                orderNumber--;

                $('input[name="product"]').val('');
                $('input[name="months"]').val('');
                $('input[name="pocet_baleni"]').val('0');
                $('input[name="date"]').val('');
                form.submit();
            });

            const clearForm = () => {
                selectedProducts.splice(0, selectedProducts.length);
                selectedMonths.splice(0, selectedMonths.length);
                baleniCount.splice(0, baleniCount.length);
                dates.splice(0, dates.length);
                insuranceDiscount.splice(0, insuranceDiscount.length);
                price.splice(0, price.length);
                selectedInsurance[0].id = '';
                selectedInsurance[0].name = '';
                orderNumber = 0;
                content.empty();
                clearCalculatedProduct();
                $('.classicPoukaz__generatedInput').remove();
                form.find('input').each(function (){
                    !$(this).is(':checkbox') && $(this).val('');
                });
                form.find('input').attr('required', 'required');
                $('input[name="pocet_baleni"]').val('0');
                form.parent().removeClass('col-lg-8');
                summary.parent().addClass('d-none');
                $('.classicPoukaz__checkbox').find('input').prop('checked', false);
                $(document).click();
                $('html, body').animate({ scrollTop: 0 }, 0);
            };


            prefillAddress.on('click', function (e) {
                e.preventDefault();
                const street = $('input[name="adress_street"]');
                const city = $('input[name="adress_city"]');
                const psc = $('input[name="adress_psc"]');

                street.val($('input[name="street"]').val());
                city.val($('input[name="city"]').val());
                psc.val($('input[name="psc"]').val());
                checkValidity(street);
                checkValidity(city);
                checkValidity(psc);
            });

            const markAllInvalid = () => {
                form.find('input').each(function () {
                    checkValidity($(this));
                });
                $('html, body').animate({
                    scrollTop: $('.is-invalid').first().offset().top - 100
                });
            };

            const checkValidity = (input: JQuery) => {
                let valid = true;
                if (input.attr('required') && !input.val()) {
                    valid = false;
                }
                if (input.attr('type') === 'email' && !validateEmail(input.val())) {
                    valid = false;
                }
                if (input.attr('type') === 'tel' && !validatePhone(input.val())) {
                    valid = false;
                }
                if (input.attr('type') === 'number' && input.val() === '0') {
                    valid = false;
                }
                if (input.attr('name') === 'adress_psc' && !validatePsc(input.val())) {
                    valid = false;
                }
                if (input.attr('name') === 'psc' && !validatePsc(input.val())) {
                    valid = false;
                }
                valid ? input.removeClass('is-invalid') : input.addClass('is-invalid');
            };

            const validateEmail = (email: string) => {
                const re = /\S+@\S+\.\S+/;
                return re.test(email);
            }

            const validatePhone = (phone: string) => {
                // only numbers and + symbol
                const re = /^[0-9+]+$/;
                return re.test(phone);
            }

            const validatePsc = (psc: string) => {
                const re = /^[0-9]+$/;
                return re.test(psc);
            }
        })();
    }
}