import $, {DragOverEvent, DropEvent} from "jquery";

export default function poukaz_qr_module() {

    const cameraQRButton = $("#cameraQR");
    const video = cameraQRButton.siblings("video");

    const uploadQRButton = $("#uploadQR");
    const dropDown = $(".qrPoukaz__dropDown");
    const dropArea = $(".qrPoukaz__dropArea");
    const fileInput = $("#fileQR");

    const successMessage = "Obdrželi jsme Váš ePoukaz.<br>Děkujeme.",
        errorMessage = "ePoukaz se nepodařilo nahrát.<br>Zkuste to prosím znovu.";

    if (cameraQRButton.length && uploadQRButton.length) {
        (async () => {
            const {default: QrScanner} = await import(/* webpackChunkName: "QrScanner" */ "qr-scanner");
            const {default: Modal} = await import(/* webpackChunkName: "Modal" */ "./modal_module");
            const qrScanner = new QrScanner(video[0], (result: any) => sendQRResult(result));
            const modal = new Modal();
            let isScanning = false,
                isUploading = false;
            let sendingResult = false;

            const sendQRResult = (result: any) => {
                if (sendingResult || modal.isOpen()) return;
                // TODO: validate result modal.show('', 'error');
                console.log(result);


                $.post("/api/poukaz-qr", {qr: result}, (data: {success: boolean, message: string}) => {
                    if (data.success) {
                        scanOff();
                        uploadOff();
                        data.message ?
                            modal.show(data.message, 'success')
                            :
                            modal.show(successMessage, 'success');
                    } else {
                        data.message ?
                            modal.show(data.message, 'error')
                            :
                            modal.show(errorMessage, 'error');
                        sendingResult = false;
                    }
                })
                .fail(() => {
                    modal.show(errorMessage, 'error');
                    sendingResult = false;
                });
            }

            const scanOn = () => {
                isScanning = true;
                qrScanner.start().then(() => {
                    video.css("position", "static");
                    qrScanner.hasFlash() && qrScanner.turnFlashOn();
                });
            }

            const scanOff = () => {
                isScanning = false;
                qrScanner.stop();
                video.css("position", "absolute");
                qrScanner.isFlashOn() && qrScanner.turnFlashOff();
            }

            const uploadOn = () => {
                isUploading = true;
                dropDown.addClass("qrPoukaz__dropDown--active");
            }

            const uploadOff = () => {
                isUploading = false;
                dropDown.removeClass("qrPoukaz__dropDown--active");
                fileInput.val("");
            }

            cameraQRButton.on("click", () => {
                if (sendingResult) return;
                isScanning ? scanOff() : scanOn();
            });

            uploadQRButton.on("click", () => {
                if (sendingResult) return;
                isUploading ? uploadOff() : uploadOn();
            });

            dropArea.on("click", () => {
                if (sendingResult) return;
                fileInput.click();
            });

            dropArea.on("dragover", (e: DragOverEvent) => {
                e.preventDefault();
                dropArea.addClass("qrPoukaz__dropArea--hovered");
            });

            dropArea.on("dragleave", () => {
                dropArea.removeClass("qrPoukaz__dropArea--hovered");
            });

            dropArea.on("drop", (e: DropEvent) => {
                e.preventDefault();
                fileInput[0].files = e.originalEvent.dataTransfer.files;
                dropArea.removeClass("qrPoukaz__dropArea--hovered");
                registerImage();
            });

            fileInput.on("change", () => {
                registerImage();
            });

            const registerImage = () => {
                const file = fileInput[0].files[0];
                fileInput.val("");

                if (file && !sendingResult) {
                    QrScanner.scanImage(file)
                        .then((result: any) => sendQRResult(result))
                        .catch(() => modal.show(errorMessage, 'error'));
                } else {
                    modal.show(errorMessage, 'error');
                }
            }
        })();
    }
}