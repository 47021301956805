import $ from "jquery";

export default function menu_module() {
    const menuClose = $('.menu-close');
    menuClose.hide();

    $('.menu').click(function(){
        $('.menu-panel').toggleClass('show');
        $('.menu-background').toggleClass('show');
        $('.menu').hide();
        $('.menu-close').show();
    });
    menuClose.click(function(){
        $('.menu-panel').removeClass('show');
        $('.menu-background').removeClass('show');
        $('.menu').show();
        $('.menu-close').hide();
    });
}