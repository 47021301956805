import $ from "jquery";

export default function num_input_module(getOrderNum: () => number, baleniCount: number[], calculateProduct: any) {

    const numInputs: JQuery = $('.numInput');
    if (!numInputs.length) return;

    numInputs.each(function () {
        const numInput = $(this),
            input = numInput.find('input'),
            minus = numInput.find('.numInput__minus'),
            plus = numInput.find('.numInput__plus');

        minus.on('click', function () {
            let val = parseInt(input.val());
            if (val > 1) {
                input.val(--val);
                baleniCount[getOrderNum()] = val;
            }

            if (input.val() === '') {
                input.val(1);
                baleniCount[getOrderNum()] = 1;
            }
            input.removeClass('is-invalid');
            calculateProduct();
        });

        plus.on('click', function () {
            let val = parseInt(input.val());
            input.val(++val);
            baleniCount[getOrderNum()] = val;

            if (input.val() === '') {
                input.val(1);
                baleniCount[getOrderNum()] = 1;
            }
            input.removeClass('is-invalid');
            calculateProduct();
        });

        // allow only numbers in input
        input.on('input', function () {

            if (input.val().startsWith('0') && input.val().length > 1) {
                input.val(input.val().slice(1));
            }

            input.val(input.val().replace(/[^0-9]/g, ''));
            input.removeClass('is-invalid');
            calculateProduct();
        });
    });
}